<template>
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="blacklists"
            :search="search" 
            item-key="id"
            class="elevation-1"
            :loading="loading"
            loading-text="Revisando los telefonos en la lista..."
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Blacklist</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-autocomplete
                v-model="modelOrigen"
                class="mr-3"
                style="margin-top: 1.5em;"
                :items="itemsOrigen"  
                 
                color="white"
                hide-no-data
                hide-selected
                item-text="label"
                item-value="id"
                label="Origen"
                placeholder="Asistente"
                prepend-icon="mdi-robot"
                return-object
                @change="seleccionAsistente"
              ></v-autocomplete>
          <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search" class="mr-3"
                    append-icon="mdi-magnify"
                    label="Buscar numero"
                    single-line
                    hide-details
                  ></v-text-field>
                <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      :disabled=disableBtnAddHsm
                    >
                      Agregar
                    </v-btn>
                  </template>
                  <v-card>
                  <v-row style="margin: 0;"
                  > 
                    <v-col class="col-12"><v-card 
                  >
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span> 
                    </v-card-title>
  
                    <v-card-text>
                      <v-container> 
                        <v-row> 
                          
                          <v-col
                            cols="12"
                            sm="12"
                          > <v-text-field
                              v-model="editedItem.senderid"
                              label="Telefono" 
                              type="number"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                          > <v-textarea
                              v-model="editedItem.comentario"
                              label="Comentario"
                              rows="2"
                              required
                            ></v-textarea>
                          </v-col>

                        </v-row>
                         
                      </v-container>
                    </v-card-text>
  
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="guardarTelefono"
                        :loading="isLoading"
                      >
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card></v-col> 
                  </v-row>
                </v-card>
                </v-dialog> 
              </v-toolbar>
            </template>
  
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)" 
                v-if="item.status ==1" 
              >
                mdi-lock-open
              </v-icon>
              <v-icon
                small
                @click="editItem(item)"
                v-if="(item.status ==0)" 
              >
                mdi-lock-open-variant-outline
              </v-icon>
            </template>
            <template v-slot:no-data>
              <!-- <v-btn
                color="primary"
                @click="initialize"
              >
                Reiniciar
              </v-btn> -->
            </template>
          </v-data-table>
        </v-col>
      </v-row> 
    <v-snackbar
        :color="alertaModalsColor"
        v-model="alertaModals"
        :multi-line="true"
        :timeout="5000"
        centered
        outlined
        
      >
        {{ textAlertModals }}
  
        <template   >
          <v-btn 
          :color="alertaModalsColor"
            text  
            elevation="24"
             @click="alertaModals = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
  
    </v-container>
   
  </template>
  <script>
  import ApiService from '../../service/apis'
  import moment from 'moment'    
  export default {
    name:'Blacklists',
    data: () => ({
        disableBtnAddHsm : true,
        idHsmSelectect : 0 ,
        dialog: false,
        dialogDelete: false,
        selected: [{}],
        isLoading:false,
        isLoadingDeleteBtn : false,
        sesion:JSON.parse(sessionStorage.getItem('sesion')),
        search:null,
        textAlertModals:'',
        alertaPaso1:false, 
        alertaTypo : 'success',
        modelOrigen:{id:1,label:'Whatsapp Mexico'},
        typeMedia : '',
        modelTipo:null,
        alertaModalsColor : '',
        alertaModals : false, 
        itemsOrigen :[{id:1,label:'Whatsapp Mexico'},{id:2,label:'Whatsapp Hemodialisis'} ],
        headers: [
          { text: 'Telefono', align: 'start', value: 'senderid' },
          { text: 'Estatus', value: 'estatus' },
          { text: 'Comentario', value: 'comentario' },
          { text: 'Fecha Registro', value: 'createdAt' }, 
          { text: 'Acciones', value: 'actions', sortable: false },
        ],
        blacklists: [],
        editedIndex: -1,
        show1:false,
        editedItem: {
          id: 0,
          status: 1,
          senderid: '',
          comentario:''
        },
        defaultItem: {
          id: 0,
          status: 1,
          senderid: '',
          comentario:''
        },
        loading:false
      }),
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Agregar a blacklist'  : 'Modificar blacklist ' 
        },
      },
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
      created () {
        this.seleccionAsistente()
      },
      updated(){
        this.evento()
      },
      methods: {
        evento(){
          this.$emit('Hsm', this.selected)
        },
        seleccionAsistente(){  
          this.loading=true
          if(this.modelOrigen.id){
            ApiService.getBlackLists(this.modelOrigen.id).then(resp =>{  
            resp.lista.forEach(element => { 
                element.createdAt = (new Date(moment(element.createdAt ).format('YYYY-MM-DD')).toISOString().substr(0, 10)) 
                if(element.status==1){
                    element.estatus = 'Bloqueado' 
                }else{
                    element.estatus = 'Libre'
                }
            }
            )
            this.blacklists = resp.lista
            this.loading=false
            this.disableBtnAddHsm = false
          })
          }else{
            this.loading=false
            this.disableBtnAddHsm = true
            this.blacklists = []
          }    
         
        },
        initialize () {
          this.loading=true 
          ApiService.getBlackLists(this.modelOrigen.id).then(resp =>{  
            resp.lista.forEach(element => { 
                element.createdAt = (new Date(moment(element.createdAt ).format('YYYY-MM-DD')).toISOString().substr(0, 10)) 
                if(element.status==1){
                    element.estatus = 'Bloqueado' 
                }else{
                    element.estatus = 'Libre'
                }
            }
            )
            this.blacklists = resp.lista
            this.loading=false
            this.disableBtnAddHsm = false
          })
        },
        close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1 
        })
      },
      deleteItemConfirm () {
        this.isLoadingDeleteBtn = true
        let borrar = {
          id: this.idHsmSelectect,
          origen : this.modelOrigen.id
        }
        ApiService.actualizarStatusHsm(borrar).then(res =>{
          if(res){
            this.isLoadingDeleteBtn = false
            this.seleccionAsistente()
            this.closeDelete()
          }
        })
      }, 
      guardarTelefono() {   
        this.isLoading =true 
        this.editedItem.origen = this.modelOrigen.id
        this.editedItem.telefono = this.editedItem.senderid
        this.editedItem.capturista_id = this.sesion.id

        if(this.editedItem.telefono.trim().length<=0){
            this.mensajeAlertaModals('error','Es necesario espesificar un telefono.')
            this.isLoading = false 
        }else{
            ApiService.addBlacklists(this.editedItem).then(res =>{
            if(res){
                this.mensajeAlertaModals('success','Se agrego correctamente el numero.')
                this.isLoading = false 
                this.initialize()
                this.close()
            }
            })
        }
        
      }, 
      mensajeAlertaModals(tipo,texto){
        this.alertaModalsColor =tipo
        this.alertaModals =true
        this.textAlertModals =texto
      },
      editItem(item){ 
        item.origen = this.modelOrigen.id
        item.capturista_id = this.sesion.id
        ApiService.updateBlacklists(item).then(res =>{
          if(res){
            this.mensajeAlertaModals('success','Se actualizaron los datos correctamente.') 
            this.initialize() 
          }
        })
      }
      },
  }
  </script>
  <style>
  .v-application .headline {
    letter-spacing: unset;
  }
  </style>